import './App.css'
import { useMemo } from 'react'
import SignUp from './pages/SignUp'
import TripStatus from './pages/TripStatus'
import VerifyCode from './pages/VerifyCode'
import { createMuiTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { ApolloProvider } from '@apollo/client/react'
//import useMediaQuery from '@material-ui/core/useMediaQuery'
import Booking from './pages/Booking'
import config from './config.json'
import CarSelection from './pages/CarSelection'
import MyTrips from './pages/MyTrips'

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token')
  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : ''
    }
  }
})
const httpLink = createHttpLink({
  uri: `${config.API_BASE_URL}/graphql`
})
const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  fetchOptions: {
    mode: 'no-cors',
  },
})

function App() {
  // TODO: Re-enable dark mode after the issue (#1) has been fixed
  // const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  const prefersDarkMode = false
  const theme = useMemo(
    () =>
      createMuiTheme({
        typography: {
          fontFamily: 'Montserrat'
        },
        palette: {
          type: prefersDarkMode ? 'dark' : 'light',
          primary: {
            main: '#00C3E1',
            contrastText: '#fff'
          },
          secondary: {
            main: '#584995',
            contrastText: '#fff'
          }
        }
      }),
    [prefersDarkMode]
  )

  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <Router>
          <Switch>
            <Route exact path='/'>
              <SignUp />
            </Route>
            <Route path='/verify'>
              <VerifyCode />
            </Route>
            <Route path='/book'>
              <Booking />
            </Route>
            <Route path='/trip/:id'>
              <TripStatus />
            </Route>
            <Route path='/cars'>
              <CarSelection />
            </Route>
            <Route path='/trips'>
              <MyTrips />
            </Route>
          </Switch>
        </Router>
      </ThemeProvider>
    </ApolloProvider>
  )
}
export default App
