import Typography from '@material-ui/core/Typography'
import { Grid } from '@material-ui/core'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import WorkIcon from '@material-ui/icons/Work';
import PersonIcon from '@material-ui/icons/Person';


const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});

export default function TripSummary() {

    const classes = useStyles();

    return <Card className={classes.root} variant="outlined">
        <CardContent>
            <Grid container>
                <Grid container xs={6} direction='column' alignItems='flex-start'>
                    <Grid item xs={12}>
                        <Typography variant='h5' color='textSecondary' align='center'>
                            Standard
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='h6' color='textSecondary' align='center'>
                            Hyundai Elantra or similar
                        </Typography>
                    </Grid>
                    <Grid container xs={3} direction='row' alignItems='flex-start'>
                        <Grid item xs={6}>
                            <Typography variant='h6' color='primary' align='center'>
                                <WorkIcon color='primary' />
                            2
                        </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant='h6' color='primary' align='center'>
                                <PersonIcon color='primary' />
                            4
                        </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='h7' color='textSecondary' align='center'>
                            700 EGP
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    IMG
                </Grid>
            </Grid>
        </CardContent>
    </Card>
}