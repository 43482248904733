import { gql } from '@apollo/client'



export const PING = gql`
    query Ping{
        ping
    }
`

export const TRIP = gql`
    query trip($id: String){
        trip(id: $id) {
            id
            from
            to
            at
            on
            price
            car {
                plate
                make
                year
                photoURLs
            }
            driver {
                name
                phone
            }
        }
    }
`

export const MY_BOOKINGS = gql`
    query MyBookings{
        myBookings {
            id
            from
            to
            at
            on
            status
        }
    }
`